<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Fly and Ski Free<br>Terms & Conditions</h1>
        <br>
        <p class="regular-text">Get 2 weekday lift tickets when you fly to Mont-Tremblant International Airport.</p>
        <br>
        <p class="regular-text">Offer valid for all paying passengers arriving and departing from the Mont-Tremblant International Airport on Porter Airlines arriving on a Sunday or Monday and departing on a Thursday or Friday. To qualify, you need to register within 2 days of your airline seat purchase. New reservations only. You will then receive an electronic voucher that you must print and present at a Tremblant ticket counter to obtain your free lift tickets. The vouchers can only be used during your stay at Tremblant and are valid only from Monday to Friday. Free lift tickets cannot be redeemed from December 26, 2022 to January 6, 2023, from February 20 to 24, 2023, and from March 13 to 17, 2023. Vouchers and lift tickets are not transferable. Terms and conditions are subject to change without prior notice. Limited quantity offer.</p>
        <br>
        <p class="regular-text">Get $25 in promotional gaming credit at the Casino de Mont-Tremblant when you fly to Mont-Tremblant International Airport.</p>
        <br>
        <p class="regular-text">Offer valid from December 16, 2022 to March 21, 2023, and subject to Fly to the fun promotion rules, which are available at the Casino de Mont-Tremblant’s Casino Privilèges counter. Casino Privilèges membership is required. One (1) voucher per member per day, up to a total of four (4) for the duration of the promotion. <a href="https://www.aeroport-tremblant.ca/_files/ugd/9bafbd_ba005bbe9972422cbdaaa63b23c44492.pdf" target="_blank">Other conditions may apply.</a></p>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Fly and Ski Free<br>Terms & Conditions</h1>
        <br>
        <p class="regular-text">Get 2 weekday lift tickets when you fly to Mont-Tremblant International Airport.</p>
        <br>
        <p class="regular-text">Offer valid for all paying passengers arriving and departing from the Mont-Tremblant International Airport on Porter Airlines arriving on a Sunday or Monday and departing on a Thursday or Friday. To qualify, you need to register within 2 days of your airline seat purchase. New reservations only. You will then receive an electronic voucher that you must print and present at a Tremblant ticket counter to obtain your free lift tickets. The vouchers can only be used during your stay at Tremblant and are valid only from Monday to Friday. Free lift tickets cannot be redeemed from December 26, 2022 to January 6, 2023, from February 20 to 24, 2023, and from March 13 to 17, 2023. Vouchers and lift tickets are not transferable. Terms and conditions are subject to change without prior notice. Limited quantity offer.</p>
        <br>
        <p class="regular-text">Get $25 in promotional gaming credit at the Casino de Mont-Tremblant when you fly to Mont-Tremblant International Airport.</p>
        <br>
        <p class="regular-text">Offer valid from December 16, 2022 to March 21, 2023, and subject to Fly to the fun promotion rules, which are available at the Casino de Mont-Tremblant’s Casino Privilèges counter. Casino Privilèges membership is required. One (1) voucher per member per day, up to a total of four (4) for the duration of the promotion. <a href="https://www.aeroport-tremblant.ca/_files/ugd/9bafbd_ba005bbe9972422cbdaaa63b23c44492.pdf" target="_blank">Other conditions may apply.</a></p>

      </div>
      
    </section>

    <footer-bar></footer-bar>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
